import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["payment-form"]

  async confirm() {
		return true
	}

	update() {
		return true
	}

  async validate() {
		return true
	}
}
