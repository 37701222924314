import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["checkBox", "submittingIcon"]

  submit() {
    this.element.requestSubmit()
    this.checkBoxTarget.disabled = true
    this.checkBoxTarget.blur()
    this.submittingIconTarget.classList.remove("hidden")
  }
}
