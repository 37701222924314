Turbo.StreamActions.update_payment_form = function () {
  const event = new CustomEvent("payment-form:update", {
    detail: {
      amount: this.dataset.amount,
      currency: this.dataset.currency,
      reusable: this.dataset.reusable
    }
  })
  document.dispatchEvent(event)
}
