import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["link", "form", "input"]

  showForm() {
    this.formTarget.classList.toggle("hidden", false)
    this.linkTarget.classList.toggle("hidden", true)
    this.inputTarget.focus()
  }

  inputBlur(event) {
    // If the input has a value, don't hide the form
    if (event.target.value.length > 0) return
    this._hideForm()
  }

  _hideForm() {
    this.formTarget.classList.toggle("hidden", true)
    this.linkTarget.classList.toggle("hidden", false)
  }
}
