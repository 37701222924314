import { Controller } from "@hotwired/stimulus"
import URI from "urijs"

export default class extends Controller {
  static targets = ["tierRadio", "tierContainer"]

  connect() {
    this.tierRadioTargets[0].checked = true
    this.toggle();
  }

  toggle() {
    this._toggleRadios()
    this._toggleTierContainers()
  }

  _toggleTierContainers() {
    this.tierContainerTargets.forEach(element => {
      element.classList.toggle("hidden", element.dataset.tier != this._selectedTier)
    })
  }

  _toggleRadios() {
    this.tierRadioTargets.forEach(element => {
      element.parentElement.classList.toggle("bg-primary", element.checked)
      element.parentElement.classList.toggle("text-white", element.checked)
    })
  }

  get _selectedTier() {
    let checkedRadio = this.tierRadioTargets.find(element => element.checked)
    return checkedRadio.value
  }
}
