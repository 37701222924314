import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fieldsContainer", "nameInput", "line1Input", "line2Input", "cityInput", "stateInput", "postalCodeInput", "countryInput"]
  static values = {
    stripePublishableKey: String,
    googleMapsApiKey: String
  }

  initialize() {
    this.stripe = Stripe(this.stripePublishableKeyValue)
    this.stripeElements = this.stripe.elements({appearance: this._appearance})
    this.addressElement = this.stripeElements.create("address", this._elementOptions)
    this.addressElement.mount(this.fieldsContainerTarget)
  }

  connect() {
    this.addressElement.on("change", (event) => {
      this._updateHiddenFields(event.value.name, event.value.address)
    })
  }

  _updateHiddenFields(name, address) {
    this.nameInputTarget.value = name
    this.line1InputTarget.value = address.line1
    this.line2InputTarget.value = address.line2
    this.cityInputTarget.value = address.city
    this.stateInputTarget.value = address.state
    this.postalCodeInputTarget.value = address.postal_code
    this.countryInputTarget.value = address.country
  }

  get _elementOptions() {
    return {
      autocomplete: {
        mode: "google_maps_api",
        apiKey: this.googleMapsApiKeyValue
      },
      defaultValues: {
        name: this.nameInputTarget.value,
        address: {
          line1: this.line1InputTarget.value,
          line2: this.line2InputTarget.value,
          city: this.cityInputTarget.value,
          state: this.stateInputTarget.value,
          postal_code: this.postalCodeInputTarget.value,
          country: this.countryInputTarget.value
        }
      },
      display: {
        name: "organization"
      },
      fields: {
        phone: "never"
      },
      mode: "shipping"
    }
  }

  get _appearance() {
    const styles = getComputedStyle(document.body)
    return {
      theme: "stripe",
      variables: {
        fontSmooth: "always",
        colorPrimary: styles.getPropertyValue("--primary-color"),
        colorBackground: '#ffffff',
        colorText: '#1f2937',
        colorTextPlaceholder: '#6b7280',
        colorDanger: '#dc2626',
        fontSizeBase: "15px",
        spacingUnit: '.15rem',
        borderRadius: '.375rem',
      },
      rules: {
        ".Label": {
          color: 'var(--colorTextPlaceholder)',
        },
        ".Label--floating": {
          color: 'var(--colorTextPlaceholder)',
          fontSize: "12px"
        },
        ".Label--resting": {
          color: 'var(--colorTextPlaceholder)',
        },
        ".Input": {
          padding: ".475rem .875rem"
        },
        ".Input:focus": {
          borderColor: 'var(--colorText)',
          boxShadow: '0 0 0 1px var(--colorText)'
        }
      },
      labels: "floating"
    }
  }
}
