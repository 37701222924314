import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = {
		clientData: Object,
		collapsed: Boolean,
		complete: Boolean
	}
	static targets = ["paymentElement"]
  static outlets = ["payment-form"]

	initialize() {
		if (this.clientDataValue.publishable_key) {
			this.stripe = Stripe(this.clientDataValue.publishable_key, {stripeAccount: this.clientDataValue.account_id})
			this.stripeElements = this.stripe.elements({...this._elementsOptions, appearance: this._appearance})
		}
	}

	connect() {
		if (this.stripeElements && this.hasPaymentElementTarget && !this.paymentElement) {
			this.paymentElement = this.stripeElements.create("payment", this._paymentElementOptions)
			this.paymentElement.mount(this.paymentElementTarget)

			this.paymentElement.on('change', (event) => {
				this.collapsedValue = event.collapsed
				this.completeValue = event.complete
				if (!this.collapsedValue || (this.completeValue && event.value.type == "link")) {
					// When link is selected the element can be collapsed, but we still want to set the payment method.
					this.paymentFormOutlet.setPaymentMethod("stripe", event.value.type)
				} else if (this._isActiveProcessorType) {
					// If Stripe is the active processor type, but we don't have a selected payment method, we want to clear the paymentForm selections.
					this.paymentFormOutlet.setPaymentMethod(null, null)
				}
      })
		}
	}

  collapse() {
    this.paymentElement.collapse()
  }

	async confirm() {
		const functionName = this.paymentFormOutlet.clientDataValue.type == "setup" ? "confirmSetup" : "confirmPayment"
		const {error} = await this.stripe[functionName]({
			elements: this.stripeElements,
			clientSecret: this.paymentFormOutlet.clientDataValue.client_secret,
			confirmParams: this._confirmParams,
			redirect: "if_required"
		})
		if (error) {
			this.paymentFormOutlet.showError(error.message)
			return false
		}
		return true
	}

	update() {
		this.stripeElements.update(this._elementsOptions)
	}

	async validate() {
		const {error: submitError} = await this.stripeElements.submit();
		return submitError ? false : true
	}

	get isDominant() {
		// If the payment element is collapsed and the payment method is link, then we are in a dominant state.
		// None of the other payment method accordion tabs will be visible. In this case we want to make Link the dominant payment method and hide everything else.
		return this.completeValue && this.paymentFormOutlet.paymentMethodTypeValue == "link"
	}

	get _appearance() {
    const styles = getComputedStyle(document.body)
    return {
      theme: "stripe",
      variables: {
        colorPrimary: styles.getPropertyValue("--primary-color"),
        colorBackground: '#ffffff',
        colorText: '#1f2937',
        colorTextPlaceholder: '#6b7280',
        colorDanger: '#dc2626',
        fontSizeBase: "16px",
        borderRadius: '.375rem',
      },
			rules: {
        ".Input:focus": {
          borderColor: 'var(--colorText)',
          boxShadow: '0 0 0 1px var(--colorText)'
        }
      }
    }
  }

	get _confirmParams() {
		return {return_url: this.paymentFormOutlet.returnUrlValue}
	}

	get _elementsOptions() {
		return {
			amount: this.paymentFormOutlet.amountValue,
			currency: this.paymentFormOutlet.currencyValue,
			mode: this.paymentFormOutlet.amountValue > 0 ? "payment" : "setup",
			setupFutureUsage: this.paymentFormOutlet.reusableValue ? "off_session" : null
		}
	}

	get _isActiveProcessorType() {
		return this.paymentFormOutlet.processorTypeValue == "stripe"
	}

  get _paymentElementOptions() {
    return {
      layout: {
        type: "accordion",
        spacedAccordionItems: true,
        defaultCollapsed: true,
        visibleAccordionItemsCount: 5,
        radios: false
      }
    }
  }
}
