import { computePosition, offset, flip } from "@floating-ui/dom"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["button", "menu"]
	static values = { floatMenu: Boolean, menuHidden: Boolean, menuPlacement: {type: String, default: "bottom-start"} }

	initialize() {
		this.menuHidden = true
    this.menuId = `dropdown-menu-${Math.random().toString(36).substring(2, 15)}`
    this.menuTarget.id = this.menuId
    this._floatMenu()
  }

  connect() {
  	this.boundDocumentClick = this._documentClick.bind(this)
  	document.addEventListener("click", this.boundDocumentClick)
    this.boundCloseMenu = this.closeMenu.bind(this)
    document.addEventListener("modal:show", this.boundCloseMenu)
  }

  disconnect() {
  	document.removeEventListener("click", this.boundDocumentClick)
    document.removeEventListener("modal:show", this.boundCloseMenu)
  }

	toggleMenu() {
    this.menuHidden = !this.menuHidden
    if (!this.menuHidden) this._positionMenu()
    this._floatingMenuTarget.classList.toggle("!hidden", this.menuHidden)
  }

  closeMenu() {
    this.menuHidden = true
    this._floatingMenuTarget.classList.toggle("!hidden", true)
  }

  _documentClick(event) {
    const target = event.target || event.srcElement
    if (!target || !this._floatingMenuTarget) { return }
    if (this._floatingMenuTarget.contains(target) || this.element.contains(target)) { return }
    if (!this.menuHidden) {
      this.toggleMenu()
    }
  }

  get _floatingMenuTarget() {
    return document.getElementById(this.menuId)
  }

  _floatMenu() {
    // This will place the menu at the bottom of the body or dialog element.
    // This way it's positioned outside of any overflow boundaries and will always be visible.
    if (!this.floatMenuValue) { return }
    const dialogElement = this.element.closest('dialog')
    if (dialogElement) {
      dialogElement.appendChild(this.menuTarget)
    } else {
      document.body.appendChild(this.menuTarget)
    }
  }

  _positionMenu() {
    computePosition(this.buttonTarget, this._floatingMenuTarget, {
      placement: this.menuPlacementValue,
      middleware: [offset(10), flip()],
    }).then(({x, y}) => {
      Object.assign(this._floatingMenuTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }
}
