import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = ["canvas", "tooltip", "tooltipTitle", "tooltipContent", "tooltipDataPointTemplate"]
  static values = {datasets: Array}

  initialize() {
    this._setChartDefaults()
    this.boundExternalTooltipHandler = this._externalTooltipHandler.bind(this)
  }

  connect() {
    new Chart(this.canvasTarget, {
      data: {datasets: this.datasetsValue},
      options: this._chartOptions
    })
  }

  get _chartOptions() {
    return {
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
        mode: 'index',
        axis: 'x'
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            drawTicks: false
          },
          border: {
            display: false,
            dash: [5,5]
          },
          ticks: {
            display: false
          }
        },
        y: {
          stacked: this._isYStacked,
          display: false,
          // These suggested values are used to prevent charts with all 0 values showing with a negative Y axis.
          suggestedMin: 0,
          suggestedMax: 1
        }
      },
      plugins: {
        tooltip: {
          enabled: false,
          external: this.boundExternalTooltipHandler
        }
      }
    }
  }

  _externalTooltipHandler(context) {
    const {chart, tooltip} = context;

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      this.tooltipTarget.style.opacity = 0;
      return;
    }

    // Add the data point items to the tooltip
    this.tooltipContentTarget.innerHTML = "";
    tooltip.dataPoints.forEach((dataPoint) => {
      this.tooltipContentTarget.append(this._tooltipDataPointFragment(dataPoint));
    });

    // Set the tooltip title
    this.tooltipTitleTarget.textContent = "";
    this.tooltipTitleTarget.textContent = tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex]["x_label"]

    // Show and position the tooltip
    // TODO: Need to move tooltip to left side of the chart if it's too close to the right edge
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
    this.tooltipTarget.style.opacity = 1;
    this.tooltipTarget.style.left = positionX + tooltip.caretX + 'px';
    this.tooltipTarget.style.top = positionY + tooltip.caretY + 'px';
  }

  _setChartDefaults() {
    Chart.defaults.layout.padding = 7
    Chart.defaults.clip = 7
    Chart.defaults.borderColor = "#e5e7eb"
    Chart.defaults.plugins.legend.display = false
    Chart.defaults.elements.point.radius = 0
    Chart.defaults.elements.point.hoverRadius = 5
  }

  _tooltipDataPointFragment(dataPoint) {
    const fragment = this.tooltipDataPointTemplateTarget.content.cloneNode(true)
    const children = fragment.firstElementChild.children
    const dataNode = dataPoint.dataset.data[dataPoint.dataIndex]
    children[0].style.backgroundColor = dataPoint.dataset["borderColor"]
    children[1].textContent = dataPoint.dataset["label"] || dataNode["x_label"]
    children[2].textContent = dataNode["y_label"]
    return fragment
  }

  get _isYStacked() {
    return this.datasetsValue.some((dataset) => dataset["type"] === "bar")
  }

  get _hasNegativeY() {
    return this.datasetsValue.some((dataset) => dataset.data.some((data) => data["y"] < 0))
  }
}

