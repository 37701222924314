import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = {
		intentObjectId: String,
		intentObjectType: String
	}
	static targets = ["buttonsContainer"]

	connect() {
		if (this._isOrder) {
			paypal.Buttons({
			  createOrder: (data, actions) => {
			  	return this.intentObjectIdValue
			  },
	      onApprove: function(data, actions) {
	      	console.log("The order has been approved, now you need to capture it!")
	      }
	    }).render(this.buttonsContainerTarget)
		} else {
			paypal.Buttons({
			  createBillingAgreement: (data, actions) => {
			  	return this.intentObjectIdValue
			  },
	      onApprove: function(data, actions) {
	      	console.log("The billing agreement has been approved, now you need to capture it!")
	      }
	    }).render(this.buttonsContainerTarget)
		}
	}

	get _isOrder() {
		return this.intentObjectTypeValue == "order"
	}
}
