import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["error", "form", "paymentMethod", "paymentMethodButton", "processorTypeInput", "paymentMethodTypeInput", "submitButton", "submittingOverlay", "paymentMethodsContainer"]
  static values = {
    amount: Number,
    clientData: Object,
    currency: String,
    processorType: String,
    paymentMethodType: String,
    returnUrl: String,
    reusable: Boolean
  }
  static outlets = ["stripe", "paypal", "mock"]

  connect() {
    this.boundSetClientData = this._setClientData.bind(this)
    this.boundUpdate = this._update.bind(this)
    document.addEventListener("payment-form:set-client-data", this.boundSetClientData)
    document.addEventListener("payment-form:update", this.boundUpdate)
    this._togglePaymentMethodsContainer()
  }

  disconnect() {
  	document.removeEventListener("payment-form:set-client-data", this.boundSetClientData)
    document.removeEventListener("payment-form:update", this.boundUpdate)
  }

  cancel() {
    this._toggleSubmitting(false)
    this._hideError()
  }

  selectPaymentMethod(event) {
    const paymentMethodTarget = event.target.closest("[data-payment-form-target='paymentMethod']")
    const processorType = paymentMethodTarget.dataset.processorType
    const paymentMethodType = paymentMethodTarget.dataset.paymentMethodType
    this.setPaymentMethod(processorType, paymentMethodType)
  }

  setPaymentMethod(processorType, paymentMethodType) {
    this.processorTypeValue = processorType
    this.processorTypeInputTarget.value = processorType
    this.paymentMethodTypeValue = paymentMethodType
    this.paymentMethodTypeInputTarget.value = paymentMethodType
    this.clientDataValue = {}
    this._togglePaymentMethods()
    this._hideError()
  }

  showError(error) {
    this.errorTarget.textContent = error
    this.errorTarget.classList.toggle("hidden", false)
    this._toggleSubmitting(false)
  }

  async submit(event) {
    this._toggleSubmitting(true)
    this._hideError()
    if (!await this._validateProcessorTypeOutlet()) {
      this._toggleSubmitting(false)
    } else {
      this.formTarget.requestSubmit()
      return this._formPromise = new Promise(resolve => {
        // We will resolve this promise when we get the response in the _setClientData function.
        // This allows us to await the response for some processors.
        this._formResolver = resolve
      })
    }
  }

  async _confirmProcessorTypeOutlet() {
    if (!this._processorTypeOutlet) {
      this.showError("You must select a payment method.")
      return false
    } else {
      if (await this._processorTypeOutlet.confirm()) {
        window.location.assign(this.returnUrlValue)
      }
    }
  }

  _hideError() {
    this.errorTarget.classList.toggle("hidden", true)
  }

  get _isPaymentMethodRequired() {
    return this.amountValue > 0 || this.reusableValue
  }

  get _isProcessorTypeDominant() {
    switch (this.processorTypeValue) {
      case "stripe": return this.stripeOutlet.isDominant
      default: return false
    }
  }

  get _processorTypeOutlet() {
    switch (this.processorTypeValue) {
      case "stripe": return this.stripeOutlet
      case "paypal": return this.paypalOutlet
      case "mock": return this.mockOutlet
    }
  }

  get _processorTypeOutlets() {
    return [
      this.hasStripeOutlet ? this.stripeOutlet : null,
      this.hasPaypalOutlet ? this.paypalOutlet : null,
      this.hasMockOutlet ? this.mockOutlet : null
    ].filter(Boolean)
  }

  _setClientData(event) {
    this.clientDataValue = event.detail.clientData
    this.returnUrlValue = event.detail.returnUrl
    this._formResolver()
    this._confirmProcessorTypeOutlet()
  }

  _togglePaymentMethods() {
    this.paymentMethodTargets.forEach(paymentMethodTarget => {
      let collapsed = paymentMethodTarget.dataset.paymentMethodType != this.paymentMethodTypeValue || paymentMethodTarget.dataset.processorType != this.processorTypeValue
      paymentMethodTarget.classList.toggle("collapsed", collapsed)
    })
    if (this.hasStripeOutlet && this.processorTypeValue != "stripe") {
      this.stripeOutlet.collapse()
    }
    this._togglePaymentMethodButtons()
    this._toggleProcessorTypeOutlets()
  }

  _togglePaymentMethodButtons() {
    let hideSubmitButton = false
    this.paymentMethodButtonTargets.forEach(paymentMethodButtonTarget => {
      if (paymentMethodButtonTarget.dataset.paymentMethodType == this.paymentMethodTypeValue && paymentMethodButtonTarget.dataset.processorType == this.processorTypeValue) {
        paymentMethodButtonTarget.classList.toggle("hidden", false)
        hideSubmitButton = true
      } else {
        paymentMethodButtonTarget.classList.toggle("hidden", true)
      }
    })
    this.submitButtonTarget.classList.toggle("hidden", hideSubmitButton)
  }

  _togglePaymentMethodsContainer() {
    this.paymentMethodsContainerTarget.classList.toggle("hidden", !this._isPaymentMethodRequired)
  }

  _toggleProcessorTypeOutlets() {
    this._processorTypeOutlets.forEach(outlet => {
      let outletElement = outlet.element
      outletElement.classList.toggle("hidden", this._isProcessorTypeDominant && outletElement.dataset.controller != this.processorTypeValue)
    })
  }

  _toggleSubmitting(submitting) {
    this.submitButtonTarget.classList.toggle("submitting", submitting)
    this.submitButtonTarget.disabled = submitting
    this.submittingOverlayTarget.classList.toggle("hidden", !submitting)
  }

  _update(event) {
    this.amountValue = event.detail.amount
    this.currencyValue = event.detail.currency
    this.reusableValue = event.detail.reusable
    this._processorTypeOutlets.forEach(outlet => {
      outlet.update()
    })
    this._togglePaymentMethodsContainer()
    this._hideError()
    this._toggleSubmitting(false)
  }

  async _validateProcessorTypeOutlet() {
    if (!this._isPaymentMethodRequired) {
      return true
    } else if (!this._processorTypeOutlet) {
      this.showError("You must select a payment method.")
      return false
    } else {
      return await this._processorTypeOutlet.validate()
    }
  }
}
