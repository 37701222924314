import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["message"]

  connect() {
    this.boundClose = this._close.bind(this)
    this.element.addEventListener("close", this.boundClose)

    Turbo.config.forms.confirm = (message, element) => {
      this.messageTarget.innerHTML = message
      this.element.showModal()
      document.dispatchEvent(new CustomEvent("modal:show"))

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
      })
    }
  }

  disconnect() {
    if (this.boundClose) this.element.removeEventListener("close", this.boundClick)
  }

  _close(event) {
    if (this._isConfirmed) {
      this.resolvePromise(true)
    } else {
      this.resolvePromise(false)
    }
  }

  get _hasConfirmMismatch() {
    return this.textInputTarget.value != "CONFIRM"
  }

  get _isConfirmed() {
    return this.element.returnValue == "confirm"
  }
}
