import { Controller } from "@hotwired/stimulus";
import URI from "urijs";

export default class extends Controller {
  static values = { lastClickedAt: Number }

  mousedown(event) {
    this.lastClickedAtValue = Date.now()
  }

  submitStart(event) {
    this.currentFormSubmission = event.detail.formSubmission
    if (this._isSubmitting) this._toggleSubmittingClass(true)
  }

  submitEnd() {
    setTimeout(() => {
      this._toggleSubmittingClass(false);
    }, 300);
  }

  _toggleSubmittingClass(state) {
    this.element.classList.toggle("submitting", state);
  }

  get _isSubmitting() {
    // If the button was clicked in the last 100ms, it should be considered submitting.
    if (Date.now() - this.lastClickedAtValue <= 100) return true
    // If this is a button and the associated form matches the submitting form...
    if (this.currentFormSubmission.formElement == this.element.form) return true
    // If this is a turbo method link and it matches the submitting form...
    return this._formSubmissionMethod == this.element.dataset.turboMethod
      && this._formSubmissionAction == this.element.href
  }

  get _formSubmissionMethod() {
    // Turbo uses the _method query parameter to determine the method for delete/put requests
    return this._formSubmissionURI.query(true)["_method"] || this.currentFormSubmission.method
  }

  get _formSubmissionAction() {
    // Remove the _method query parameter from the action URI that is added by Turbo so that it matches the href
    return this._formSubmissionURI.removeQuery("_method").toString()
  }

  get _formSubmissionURI() {
    return new URI(this.currentFormSubmission.action)
  }
}
