import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]
  static values = {
    selectedIndex: Number
  }

  connect() {
    this._togglePanels()
  }

  select(event) {
    this.selectedIndexValue = event.params.index
    this._togglePanels()
  }

  _togglePanels() {
    this.panelTargets.forEach(panel => panel.classList.add("hidden"))
    this.panelTargets[this.selectedIndexValue].classList.remove("hidden")
    this.tabTargets.forEach(tab => tab.classList.remove("active-link"))
    this.tabTargets[this.selectedIndexValue].classList.add("active-link")
  }
}
